
//公共库
import { Component, Vue } from "vue-property-decorator";

//组件
@Component({
  name: "Page404",
})

//组件代码
export default class extends Vue {}
